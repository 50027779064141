<template>
<span>
   <!-- TODO: 
   more on mobile goes in wrong day
   1 day before for the offset
   IMPROVEMENTS:
   - APIService can be initiated only if needed (based on role)
   -->
   <!-- HISTORY:
   - V250131.1: Made offset optional for the export consumerType and changed their hints + Fixed customOffset validation.
   - V230227.1: In weekItems, started weeks from Sunday (instead of Monday).
   - V230124.1: Modified styles again to display contents properly after Aref's upgrade.
   - V221130.1: Added getInstructionNotifications() in order to replace the password and pgpPublicKey values with "***" +
      Modified some styles to fix UI issues in 'view' mode.
   - V221117.1: Added debugSwitch to the props and watch + Made the debug switch disabled for edit/delete + Added changeDebug() +
      Created setNameValidator() to refresh cronsData based on the debug switch + Added showOffset prop.
   - V221027.1: Applied/Modified styles to display contents properly after Aref's upgrade + Replaced hasOwnProperty() with hasOwn from mixins +
      Changed debug switch permission from isPowerAdmin to isAdminUser.
   - 08/16/22(B1.6): Added debug switch for power admin.
   - 07/14/22(B1.5): Modified submitClicked() to separate update from create event.
   - 05/02/22(B1.4): Implemented logout feature.
   - 07/27/21(B1.3): Moved createCron() functionality from parent to here + Raised cancel event + Made dialog's style dynamic +
      Added debug, isJsonServer, small and xSmall props + Set default for noPermissionMessage prop + Added logic to get cronsData and timezones.
   - 07/26/21(B1.2): Brought back the offset for export and added it to the instruction property.
   - 07/19/21(B1.1): For export, hid offset and excluded it from instruction along with download property.
   - 07/19/21(B1.0): Moved it to the IAM project + Added consumerType prop + Merged all v-expansion-panels into one.
   - 01/19/21(B0.13): Added from and body-text + set default contents for both body fields.
   - 01/15/21(B0.12): Added notification details (fromName, subject, and body) + override RDL.
   - 12/15/20(B0.11): Commented Multiple Files switch.
   - 09/08/20(B0.10): Added watch for value to wait for data + Removed unused isYesterday in date-fns. 
   - 09/03/20(B0.9): Hid password in view and edit cases.
   - 09/01/20(B0.8): Added ftpFolder.
   - 08/21/20(B0.7): Added logic to handle tooltip functionality for unauthorized users including 'delete' for btnRole.
   - 06/03/20(B0.6): Moved timezones out of the component and got it via a prop + Fixed issue with showing FTP +
      Changed FTP Settings to File Transfer Settings + Changed schedules info layout + Set min for startDate +
      Added validations for startDate to be after now and endDate to be 2 days greater than startDate +
      Fixed a bug related to custom offset + Fixed required validation for weekly and monthly + Added day-before-1 to offsetItems.
   - 06/02/20(B0.5): Fixed two bugs related to FTP in view/edit & endDate in CRON.
   - 05/27/20(B0.4): Made SFTP lower + Changed mdi-eye icon.
   - 05/22/20(B0.3): Cleaned up commected codes. 
   - 05/22/20(B0.2): Remobed 'Repeating'. 
   - 05/22/20(B0.1): 1st release. 
   -->
   <div class="flex-grow-1" v-if="btnAlign==='right'"></div>
   <v-tooltip v-model="showTooltip" top>
      <template v-slot:activator="{ on }">
         <v-btn v-if="btnLabel"
            v-on="on"
            :small="small"
            :x-small="xSmall"
            :text="btnText"
            :color="btnColor"
            :disabled="btnDisabled"
            @click="btnClicked"
         >{{ btnLabel }}
            <v-icon right dark>{{ btnIcon }}</v-icon>
         </v-btn>

         <v-icon v-else
            :color="btnColor"
            :disabled="btnDisabled"
            @click="btnClicked"
         >{{btnIcon}}</v-icon>
      </template>
      <span>{{noPermissionMessage}}</span>
   </v-tooltip>               

   <v-dialog v-model="dialog" persistent no-click-animation scrollable max-width="1200px">
      <!-- <template v-slot:activator="{ on }"></template> -->
      <v-card :loading="loading">
         <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">
            <span>{{ dialogTitle }}</span>
         </v-card-title>

         <v-card-text class="pb-1" :style="dialogStyle">
            <v-form ref="cronForm" v-model="isCronFormValid" lazy-validation>

               <v-expansion-panels multiple focusable
                  v-model="cronPanels"
               >
                  <v-expansion-panel :readonly="consumerType === 'export'">
                     <v-expansion-panel-header class="py-0"
                        :hide-actions="consumerType === 'export'"
                     >Cron Settings
                     </v-expansion-panel-header>
                     <v-expansion-panel-content v-if="btnRole==='edit'"
                        class="pt-1 pb-2"
                     >
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 pt-2 pb-0">
                              <v-text-field
                                 ref="name"
                                 v-model="cron.name"
                                 :label="nameLabel"
                                 :placeholder="namePlaceholder"
                                 :outlined="nameOutlined"
                                 :rules="formRules.name"
                                 @input="nameChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 pt-2 pb-0">
                              <v-text-field
                                 ref="emails"                           
                                 v-model="cron.emails"
                                 :label="emailLabel"
                                 :placeholder="emailPlaceholder"
                                 :outlined="emailOutlined"
                                 :rules="formRules.emails"
                                 @input="emailChanged"
                              ></v-text-field>
                           </v-col>
                        </v-row>

                        <v-row>
                           <v-col cols="12" sm="6" md="3" class="text-center my-0 py-0">
                              <v-select dense hide-selected
                                 ref="period"
                                 v-model="formValues.period"
                                 :items="periodItems"
                                 :label="periodLabel"
                                 :placeholder="periodPlaceholder"
                                 :rules="formRules.period"
                                 :disabled="Boolean(value._id)"
                                 @change="periodChanged"
                              ></v-select>
                           </v-col>

                           <v-col cols="12" sm="6" md="3" class="text-center my-0 py-0" v-if="formValues.period==='W'">
                              <v-select dense multiple
                                 ref="week"
                                 v-model="formValues.week"
                                 :items="weekItems"
                                 :label="weekLabel"
                                 :placeholder="weekPlaceholder"
                                 :rules="formRules.week"
                                 :disabled="Boolean(value._id)"
                                 @change="weekChanged"
                              >
                                 <template v-slot:prepend-item v-if="weekdaysNeeded">
                                    <v-list-item ripple @click="weekdaysClicked">
                                       <v-list-item-content>
                                          <v-list-item-title>Mon - Fri</v-list-item-title>
                                       </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-0"></v-divider>
                                 </template>
                              </v-select>
                           </v-col>

                           <v-col cols="12" sm="6" md="3" class="text-center my-0 py-0" v-if="formValues.period==='M'">
                              <v-select dense multiple
                                 ref="day"
                                 v-model="formValues.day"
                                 :items="dayItems"
                                 :label="dayLabel"
                                 :placeholder="dayPlaceholder"
                                 :rules="formRules.day"
                                 :disabled="Boolean(value._id)"
                                 @change="dayChanged"
                              ></v-select>
                           </v-col>
                           
                           <v-col cols="8" sm="8" md="2" class="text-center my-0 py-0" v-if="formValues.period == 'O' || formValues.period == 'D' || formValues.week.length || formValues.day.length || formValues.hour">
                              <v-select dense hide-selected
                                 ref="hour"
                                 v-model="formValues.hour"
                                 :items="hourItems"
                                 :label="hourLabel"
                                 :placeholder="hourPlaceholder"
                                 :rules="formRules.hour"
                                 :disabled="Boolean(value._id)"
                                 @change="hourChanged"
                              ></v-select>
                           </v-col>
                           <v-col cols="4" sm="4" md="1" class="text-center my-0 py-0" v-if="formValues.hour">
                              <v-select dense persistent-hint hide-selected hide-details
                                 v-model="formValues.minute"
                                 :items="minuteItems"
                                 :label="minuteLabel"
                                 :placeholder="minutePlaceholder"
                                 :disabled="Boolean(value._id)"
                              ></v-select>
                           </v-col>
                           <v-col cols="12" sm="12" md="3" class="text-center my-0 py-0" v-if="formValues.hour">
                              <v-select dense hide-selected
                                 placeholder="Timezone"
                                 item-text="name"
                                 item-value="aliasOf"
                                 v-model="cron.timezone"
                                 :items="myTimezones"
                                 :rules="formRules.timezone"
                                 :disabled="Boolean(value._id)"
                              ></v-select>
                           </v-col>
                        </v-row>
                     
                        <v-row dense class="py-0 my-0" v-if="formValues.hour">
                           <v-col cols="12" class="my-0 py-0 font-italic grey--text">
                              {{prettyCronSchedule}} <span>({{cronSchedule}})</span>
                           </v-col>
                        </v-row>

                        <v-row>
                           <v-col v-if="showOffset"
                              cols="12" sm="6" md="4" lg="4"
                              class="pt-1 pb-0 mb-0"
                           >
                              <v-select dense persistent-hint hide-selected
                                 ref="offset"
                                 v-model="formValues.offset"
                                 :items="offsetItems"
                                 :label="offsetLabel"
                                 :placeholder="offsetPlaceholder"
                                 :hint="offsetHint"
                                 :rules="formRules.offset"
                                 :disabled="Boolean(value._id)"
                                 @change="offsetChanged"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.offset=='C'"
                              cols="12" sm="6" md="2" lg="2" class="pt-1"
                           >
                                 <!-- @change="customOffsetChanged" -->
                              <v-text-field dense persistent-hint
                                 type="number" 
                                 min="4"
                                 :max="offsetMaxDays"
                                 :disabled="Boolean(value._id)"
                                 :rules="formRules.customOffset"
                                 v-model="formValues.customOffset"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" sm="4" md="2" lg="2" class="pt-0">
                              <v-menu offset-y
                                 transition="scale-transition"
                                 max-width="290px"
                                 min-width="290px"
                                 :close-on-content-click="false"
                                 :disabled="Boolean(value._id)"
                                 v-model="menuStart"
                              >
                                 <template v-slot:activator="{ on }">
                                    <v-text-field persistent-hint readonly
                                       class="pt-0 mt-1"
                                       label="Start Date"
                                       append-icon="event"
                                       :rules="formRules.startDate"
                                       v-on="on"
                                       v-model="formattedStartDate"
                                       :disabled="Boolean(value._id)"
                                    ></v-text-field>
                                 </template>
                                 <v-date-picker no-title
                                    :min="minStartDate"
                                    v-model="cron.startDate"
                                    @input="menuStart = false"
                                    @change="startDateChanged"
                                    :disabled="Boolean(value._id)"
                                 ></v-date-picker>
                              </v-menu>
                           </v-col>
                           <v-col v-if="formValues.period && formValues.period != 'O'"
                              cols="12" xs="12" sm="4" md="2" lg="2"
                              class="my-0 pt-2 pb-0"
                           >
                              <v-select dense hide-selected
                                 ref="endType"
                                 label="End Type"
                                 v-model="formValues.endType"
                                 :items="endTypeItems"
                                 :disabled="Boolean(value._id)"
                              ></v-select>
                           </v-col>
                           <v-col v-if="formValues.endType==='D'"
                              cols="12" sm="4" md="2" lg="2"
                              class="pt-0"                           
                           >
                              <v-menu offset-y
                                 transition="scale-transition"
                                 max-width="290px"
                                 min-width="290px"
                                 :close-on-content-click="false"
                                 v-model="menuEnd"
                                 :disabled="Boolean(value._id)"
                              >
                                 <template v-slot:activator="{ on }">
                                    <v-text-field persistent-hint readonly
                                       label="End Date"
                                       class="pt-0 mt-2"
                                       append-icon="event"
                                       v-on="on"
                                       v-model="formattedEndDate"
                                       :rules="formRules.endDate"
                                       :disabled="Boolean(value._id)"
                                    ></v-text-field>
                                 </template>
                                 <v-date-picker no-title
                                    :min="minEndDate"
                                    v-model="cron.endDate"
                                    @input="menuEnd = false"
                                    :disabled="Boolean(value._id)"
                                 ></v-date-picker>
                              </v-menu>
                           </v-col>
                           <v-col v-if="formValues.endType==='N'"
                              cols="12" sm="4" md="2" lg="2"
                              class="pt-0"
                           >
                              <v-text-field persistent-hint
                                 class="pt-1"
                                 label="Number of Repeats"
                                 v-model="cron.repeat"
                                 :rules="formRules.repeat"
                                 :disabled="Boolean(value._id)"
                              ></v-text-field>
                           </v-col>
                        </v-row>

                        <v-row v-if="rdls.length > 0">
                           <v-col cols="12" sm="6" md="4" lg="4" class="pt-1">
                                 <!-- :clearable="formRules.rdl.length === 0" -->
                              <v-select dense hide-selected clearable
                                 ref="rdl"
                                 v-model="formValues.rdl"
                                 :items="rdls"
                                 :label="rdlLabel"
                                 :placeholder="rdlPlaceholder"
                                 :rules="formRules.rdl"
                                 :disabled="Boolean(value._id)"
                              ></v-select>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>

                     <v-expansion-panel-content v-else class="pt-1 pb-5">
                        <v-row class="pt-5">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Name: </span>
                              <span :class="colContentClass">{{cron.name}}</span>
                           </v-col>
                           <!-- <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <v-row class="pt-0">
                                 <v-col cols="12" xs="12" sm="4" md="4" lg="4" class="my-0 py-0 pr-0">
                                    <span :class="colTitleClass">{{emailLabel}}: </span>
                                 </v-col>
                                 <v-col cols="12" xs="12" sm="8" md="8" lg="8" class="my-0 py-0 pl-0">
                                    <p class="pb-0 mb-0" v-for="e in cron.emails.split(',')" :key="e">
                                       <span :class="colContentClass">{{ e }}</span>
                                    </p>
                                 </v-col>
                              </v-row>
                           </v-col> -->
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">{{emailLabel}}: </span>
                              <span :class="colContentClass">{{cron.emails. split(',').join(', ')}}</span>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Cron: </span>
                              <span :class="colContentClass" v-if="formValues.hour">{{prettyCronSchedule}}{{cron.timezone?` (${getTimezoneName(cron.timezone)})`:''}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Offset: </span>
                              <span :class="colContentClass">{{offsetHint}}</span>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Start Date: </span>
                              <span :class="colContentClass">{{formatDate(cron.startDate)}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">{{cron.repeat?'Repeat':'End Date'}}: </span>
                              <span :class="colContentClass">{{cron.repeat?`${cron.repeat} times`:formatDate(cron.endDate)}}</span>
                           </v-col>
                        </v-row>
                        <v-row v-if="overrideRdl">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">RDL: </span>
                              <span :class="colContentClass">{{overrideRdl}}</span>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               <!-- </v-expansion-panels> -->

               <!-- <v-expansion-panels multiple focusable               
                  class="pt-4"          
                  v-model="downloadPanels"
               > -->
                  <v-expansion-panel v-if="consumerType != 'export'">
                     <v-expansion-panel-header class="py-0">Download Settings</v-expansion-panel-header>
                     <v-expansion-panel-content v-if="btnRole==='edit'" class="pb-4">
                        <v-row class="pt-3">
                           <v-col cols="12" xs="12" sm="12" md="9" lg="9" class="my-0 py-0">
                              <v-text-field persistent-hint counter
                                 ref="fileName"
                                 prepend-icon="folder"
                                 v-model="downloadSettings.fileName"
                                 :label="fileNameLabel"
                                 :placeholder="fileNamePlaceholder"
                                 :hint="fileNameHint"
                                 :outlined="fileNameOutlined"
                                 :rules="fileNameRules"
                                 @input="fileNameChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="pt-4 pb-0 mb-0">
                              <v-select dense hide-selected hide-details
                                 ref="delimiter"
                                 label="Delimiter:"
                                 v-model="downloadSettings.delimiter"
                                 :items="delimiterItems"
                              ></v-select>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="py-0 my-0">
                              <v-switch dense small hide-details
                                 v-model="downloadSettings.addQuotes"
                              >
                                 <template v-slot:label>
                                    <div class="caption">Add Quotes</div>
                                 </template>
                              </v-switch>
                           </v-col>
                           <!-- <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="py-0 my-0">
                              <v-switch dense small hide-details
                                 v-model="downloadSettings.multipleFiles"
                              >
                                 <template v-slot:label>
                                    <div class="caption">Multiple Files</div>
                                 </template>
                              </v-switch>
                           </v-col> -->
                           <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="py-0 my-0">
                              <v-switch dense small hide-details
                                 v-model="downloadSettings.zipped"
                              >
                                 <template v-slot:label>
                                    <div class="caption">Zip File(s)</div>
                                 </template>
                              </v-switch>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="3" lg="3" class="pt-0 mt-0">
                              <v-switch dense small hide-details
                                 v-model="downloadSettings.overwrite"
                              >
                                 <template v-slot:label>
                                    <div class="caption">Overwrite</div>
                                 </template>
                              </v-switch>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>
                     <v-expansion-panel-content class="py-5" v-else>
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">{{fileNameLabel || fileNamePlaceholder}}: </span>
                              <span :class="colContentClass">{{downloadSettings.fileName}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                              <span :class="colTitleClass">Delimiter: </span>
                              <span :class="colContentClass">{{delimiterItems.find(d => d.value===downloadSettings.delimiter).text}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                              <span :class="colTitleClass">Add Quotes: </span>
                              <span :class="colContentClass">{{downloadSettings.addQuotes}}</span>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Multiple Files: </span>
                              <span :class="colContentClass">{{downloadSettings.multipleFiles}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                              <span :class="colTitleClass">Zip File(s): </span>
                              <span :class="colContentClass">{{downloadSettings.zipped}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                              <span :class="colTitleClass">Overwrite: </span>
                              <span :class="colContentClass">{{downloadSettings.overwrite}}</span>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               <!-- </v-expansion-panels> -->

               <!-- <v-expansion-panels multiple focusable
                  class="pt-4"          
                  v-model="ftpPanels"
               > -->
                  <v-expansion-panel v-if="consumerType != 'export'">
                     <v-expansion-panel-header class="py-0">File Transfer Settings</v-expansion-panel-header>
                     <v-expansion-panel-content v-if="btnRole==='edit'" class="pt-4 pb-5">
                        <v-row>
                           <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="py-0">
                              <v-select dense hide-selected
                                 class="mt-0 pt-2"
                                 v-model="cron.ftp.protocol"
                                 :items="protocolItems"
                                 :label="protocolLabel"
                                 :placeholder="protocolPlaceholder"
                                 :rules="formRules.protocol"
                                 @change="protocolChanged"
                              ></v-select>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="py-0" v-if="cron.ftp.protocol != ''">
                              <v-text-field persistent-hint single-line
                                 ref="host"
                                 class="mt-0 pt-1"
                                 :hint="cron.ftp.host ? hostPlaceholder : ''"
                                 :label="hostLabel"
                                 :placeholder="hostPlaceholder"
                                 :rules="formRules.host"
                                 v-model="cron.ftp.host"
                                 @input="hostChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="py-0" v-if="cron.ftp.protocol != ''">
                              <v-text-field persistent-hint single-line
                                 ref="folder"
                                 class="mt-0 pt-1"
                                 :hint="`${(cron.ftp.folder ? folderPlaceholder + ': ' : '') + folderHint}`"
                                 v-model="cron.ftp.folder"
                                 :label="folderLabel"
                                 :placeholder="folderPlaceholder"
                                 :rules="formRules.folder"
                                 @input="folderChanged"
                              ></v-text-field>
                           </v-col>
                        </v-row>
                        <v-row class="pt-1">
                           <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="py-0" v-if="cron.ftp.protocol != ''">
                              <v-text-field single-line
                                 type="number"
                                 min="25"
                                 class="mt-0 pt-0"
                                 :hint="cron.ftp.port ? portPlaceholder : ''"
                                 :label="portLabel"
                                 :placeholder="portPlaceholder"
                                 v-model="cron.ftp.port"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="py-0" v-if="cron.ftp.protocol != ''">
                              <v-text-field single-line
                                 ref="username"
                                 class="mt-0 pt-0"
                                 :hint="cron.ftp.username ? usernamePlaceholder : ''"
                                 :label="usernameLabel"
                                 :placeholder="usernamePlaceholder"
                                 :rules="formRules.username"
                                 v-model="cron.ftp.username"
                                 @input="usernameChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="py-0" v-if="cron.ftp.protocol != ''">
                              <v-text-field single-line counter persistent-hint
                                 ref="password"
                                 class="mt-0 pt-0"
                                 v-model="cron.ftp.password"
                                 :hint="cron.ftp.password ? passwordHint || passwordPlaceholder : ''"
                                 :label="passwordLabel"
                                 :placeholder="passwordPlaceholder"
                                 :type="showPassword ? 'text' : 'password'"
                                 :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                 :rules="formRules.password"
                                 @click:append="showPassword = !showPassword"
                                 @input="passwordChanged"
                              ></v-text-field>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>

                     <v-expansion-panel-content v-else class="px-0 pt-3 pb-5">
                        <div v-if="cron.ftp.protocol">
                           <v-row class="pt-2">
                              <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                                 <span :class="colTitleClass">Host: </span>
                                 <span :class="colContentClass">{{cron.ftp.host}}</span>
                              </v-col>
                              <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">Folder: </span>
                                 <span :class="colContentClass">{{cron.ftp.folder}}</span>
                              </v-col>
                              <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">Protocol: </span>
                                 <span :class="colContentClass">{{cron.ftp.protocol}}</span>
                              </v-col>
                           </v-row>
                           <v-row>
                              <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                                 <span :class="colTitleClass">User Name: </span>
                                 <span :class="colContentClass">{{cron.ftp.username}}</span>
                              </v-col>
                              <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">Password: </span>
                                 <span :class="colContentClass">**********</span>
                                 <!-- <span :class="colContentClass">{{cron.ftp.password}}</span> -->
                              </v-col>
                              <v-col cols="12" xs="12" sm="3" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">Port: </span>
                                 <span :class="colContentClass">{{cron.ftp.port}}</span>
                              </v-col>
                           </v-row>
                        </div>
                        <div v-else class="py-2">FTP has not been set up.</div>
                     </v-expansion-panel-content>

                  </v-expansion-panel>
               <!-- </v-expansion-panels> -->

               <!-- <v-expansion-panels multiple focusable
                  class="pt-4"          
                  v-model="notificationPanels"
               > -->
                  <v-expansion-panel v-if="consumerType != 'export'">
                     <v-expansion-panel-header class="py-0">Email Notification Settings</v-expansion-panel-header>
                     <v-expansion-panel-content v-if="btnRole==='edit'" class="pt-2 pb-2">
                        <v-row class="pt-4">
                           <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="py-2">
                              <v-text-field persistent-hint clearable
                                 class="mt-0 pt-0"
                                 ref="from"
                                 :label="fromLabel"
                                 :placeholder="fromPlaceholder"
                                 :rules="formRules.from"
                                 v-model="notificationSettings.from"
                                 @input="fromChanged"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="py-2" v-if="notificationSettings.from">
                              <v-text-field persistent-hint
                                 class="mt-0 pt-0"
                                 ref="fromName"
                                 :label="fromNameLabel"
                                 :placeholder="fromNamePlaceholder"
                                 :rules="[rules.required]"
                                 v-model="notificationSettings.fromName"
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-2" v-if="notificationSettings.from">
                              <v-text-field
                                 class="mt-0 pt-0"
                                 ref="subject"
                                 :label="subjectLabel"
                                 :placeholder="subjectPlaceholder"
                                 :rules="[rules.required]"
                                 v-model="notificationSettings.subject"
                              ></v-text-field>
                           </v-col>
                        </v-row>
                        <v-row class="pt-2">
                           <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0" v-if="notificationSettings.from">
                              <v-textarea outlined
                                 ref="bodyHtml"
                                 class="mt-0 pt-0 caption"
                                 v-model="notificationSettings.bodyHtml"
                                 :label="bodyHtmlLabel"
                                 :placeholder="bodyHtmlPlaceholder"
                                 :rules="[rules.required]"
                              ></v-textarea>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0" v-if="notificationSettings.from">
                              <v-textarea outlined
                                 ref="bodyText"
                                 class="mt-0 pt-0 caption"
                                 v-model="notificationSettings.bodyText"
                                 :label="bodyTextLabel"
                                 :placeholder="bodyTextPlaceholder"
                              ></v-textarea>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>

                     <v-expansion-panel-content v-else class="px-0">
                        <div v-if="notificationSettings.from">
                           <v-row class="pt-2">
                              <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">From Email: </span>
                                 <span :class="colContentClass">{{notificationSettings.from}}</span>
                              </v-col>
                              <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="my-0 py-0">
                                 <span :class="colTitleClass">From Name: </span>
                                 <span :class="colContentClass">{{notificationSettings.fromName}}</span>
                              </v-col>
                              <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="my-0 py-0">
                                 <span :class="colTitleClass">Subject: </span>
                                 <span :class="colContentClass">{{notificationSettings.subject}}</span>
                              </v-col>
                           </v-row>
                           <v-row>
                              <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="my-0 py-0">
                                 <v-textarea hide-details outlined readonly
                                    class="mx-0 pt-2 caption"
                                    rows="2"
                                    type="html"
                                    v-model="notificationSettings.bodyHtml"
                                 >
                                    <template v-slot:label>
                                       <div :class="colTitleClass">{{bodyHtmlLabel}}</div>
                                    </template>
                                 </v-textarea>
                              </v-col>
                              <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="my-0 py-0">
                                 <v-textarea hide-details outlined readonly
                                    class="mx-0 pt-2 caption"
                                    rows="2"
                                    type="text"
                                    v-model="notificationSettings.bodyText"
                                 >
                                    <template v-slot:label>
                                       <div :class="colTitleClass">{{bodyTextLabel}}</div>
                                    </template>
                                 </v-textarea>
                              </v-col>
                           </v-row>
                        </div>
                        <div v-else class="py-2">Email notification details have not been specified.</div>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>

               <v-expansion-panels v-if="btnRole==='view'"
                  multiple focusable
                  class="pt-4"          
                  v-model="viewPanels"
               >
               <!-- v-if="exportInstruction.keys.length > 0" -->
                  <v-expansion-panel v-if="consumerType==='export'">
                     <v-expansion-panel-header class="py-0">Export Settings</v-expansion-panel-header>
                     <v-expansion-panel-content class="px-0 pt-2 pb-5">
                        <v-row class="pt-2">
                           <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Name: </span>
                              <span :class="colContentClass">{{getInstructionValue('name')}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Delimiter: </span>
                              <span :class="colContentClass">{{getInstructionValue('delimiter')}}</span>
                           </v-col>
                        </v-row>
                        <v-row class="pt-2">
                           <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="my-0 py-0">
                              <span :class="colTitleClass">Fields: </span>
                              <span :class="colContentClass">{{getInstructionValue('exportFields')}}</span>
                           </v-col>
                        </v-row>
                        <v-row class="pt-2">
                           <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="my-0 py-0">
                              <span :class="colTitleClass">Filter: </span>
                              <span :class="colContentClass">{{getInstructionValue('aggregateQuery')}}</span>
                           </v-col>
                        </v-row>
                        <v-row class="pt-2">
                           <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="my-0 py-0">
                              <span :class="colTitleClass">Notifications: </span>
                              <span :class="colContentClass">{{getInstructionNotifications()}}</span>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                     <v-expansion-panel-header class="py-0">Schedules Information</v-expansion-panel-header>
                     <!-- <v-expansion-panel-content class="px-0">
                        <v-row class="pt-2" v-if="!isObjectEmpty(nextScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Next Schedule Date: </span>
                              <span :class="colContentClass">{{nextScheduleData.scheduleDate}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Run Sequence: </span>
                              <span :class="colContentClass">{{nextScheduleData.runSequence}}</span>
                           </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-row class="pt-2" v-if="!isObjectEmpty(prevScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Last Schedule Date: </span>
                              <span :class="colContentClass">{{prevScheduleData.scheduleDate}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Run Sequence: </span>
                              <span :class="colContentClass">{{prevScheduleData.runSequence}}</span>
                           </v-col>
                        </v-row>
                        <v-row v-if="!isObjectEmpty(prevScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Started Date: </span>
                              <span :class="colContentClass">{{prevScheduleData.startDate}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Ended Date: </span>
                              <span :class="colContentClass">{{prevScheduleData.endDate}}</span>
                           </v-col>
                        </v-row>
                        <v-row v-if="!isObjectEmpty(prevScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Processed Records: </span>
                              <span :class="colContentClass">{{prevScheduleData.processedRecords}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Recovery Count: </span>
                              <span :class="colContentClass">{{prevScheduleData.recoveryCount}}</span>
                           </v-col>
                        </v-row>
                        <v-row v-if="!isObjectEmpty(prevScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">File Transfer Status: </span>
                              <span :class="colContentClass">{{prevScheduleData.fileTransferStatus}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">File URL: </span>
                              <span :class="colContentClass">{{prevScheduleData.fileUrl}}</span>
                           </v-col>
                        </v-row>
                        <v-row v-if="!isObjectEmpty(prevScheduleData)">
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">File Transfer Recovery Count: </span>
                              <span :class="colContentClass">{{prevScheduleData.fileTransferRecoveryCount}}</span>
                           </v-col>
                           <v-col cols="12" xs="12" sm="6" md="6" lg="6" class="my-0 py-0">
                              <span :class="colTitleClass">Comment: </span>
                              <span :class="colContentClass">{{prevScheduleData.comment}}</span>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content> -->

                     <v-expansion-panel-content class="px-0 pt-2 pb-5">
                        <v-row>
                           <v-col cols="12" md="6" lg="6" xl="6">
                              <p class="text-center mb-2" :class="colTitleClass" >
                                 Last Completed Schedule{{prevScheduleProps && prevScheduleProps.length>0?'':': N/A'}}
                              </p>
                              <v-row v-for="prop in prevScheduleProps" :key="prop">
                                 <v-col cols="12" class="py-0">
                                    <span :class="colTitleClass">{{prop}}: </span>
                                    <span :class="colContentClass">{{prevScheduleData[prop]}}</span>
                                 </v-col>
                              </v-row>
                           </v-col>
                           <!-- <v-divider vertical></v-divider> -->
                           <v-col cols="12" md="6" lg="6" xl="6">
                              <p class="text-center mb-2" :class="colTitleClass" >
                                 Next Schedule{{nextScheduleProps && nextScheduleProps.length>0?'':': N/A'}}
                              </p>
                              <!-- <p class="text-center" :class="colTitleClass">Next Schedule</p> -->
                              <v-row v-for="prop in nextScheduleProps" :key="prop">
                                 <v-col cols="12" class="py-0">
                                    <span :class="colTitleClass">{{prop}}: </span>
                                    <span :class="colContentClass">{{nextScheduleData[prop]}}</span>
                                 </v-col>
                              </v-row>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-content>

                  </v-expansion-panel>
               </v-expansion-panels>
               
            </v-form>
         </v-card-text>

         <!-- <v-card-text class="pb-0" style="height: 95vh" v-else>
            <v-container class="px-0 pb-0">
               <v-form ref="cronForm" v-model="isCronFormValid" lazy-validation>

               <v-expansion-panels multiple focusable               
                  v-model="panels"
               >
                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-action>Cron Settings</v-expansion-panel-header>
                  </v-expansion-panel>

                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-action>FTP Settings</v-expansion-panel-header>
                  </v-expansion-panel>

                  <v-expansion-panel readonly>
                     <v-expansion-panel-header hide-action>Download Settings</v-expansion-panel-header>
                  </v-expansion-panel>
               </v-expansion-panels>


               </v-form>
            </v-container>
         </v-card-text> -->

         <v-card-actions class="py-0">
            <v-switch v-show="user.isAdminUser"
               class="mx-0 my-0 pl-3 pt-3 pb-0"
               label="Debug"
               :disabled="cron._id != ''"
               @change="debugChanged"
               v-model="cron.debug"
            ></v-switch>
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               :class="btnRole==='edit' ? '' : 'pr-5'"
               @click="cancelClicked"
            >{{btnRole==='edit'?'Cancel':'Close'}}</v-btn>
            <v-btn v-if="btnRole==='edit'"
               text
               class="ml-0 mr-1 pr-5"
               color="blue darken-1"
               :disabled="false"
               @click="submitClicked"
            >Submit</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</span>
</template>

<script>
import cronstrue from 'cronstrue';
import { addDays, addHours, addMinutes, format, parse, parseISO, isAfter, isEqual, setHours, setMinutes } from "date-fns";
import { APIService } from '../services/bt-cron-scheduler-api-service.js';
import countriesAndTimezones from 'countries-and-timezones';
import { hasOwn } from '../mixins/bt-mixin.js';

class Ftp {
   constructor(initVal) {
      this.protocol = initVal.transferProtocol || '';
      this.host = initVal.ftpHost || '';
      this.folder = initVal.ftpFolder || '';
      this.port = initVal.ftpPort || '';
      this.username = initVal.ftpUser || '';
      this.password = initVal.ftpPass || '';
   }
}

class DownloadSettings {
   constructor() {
      this.fileName = '';
      this.delimiter = ',';
      this.multipleFiles = false;
      this.zipped = false;
      this.addQuotes = false;
      this.overwrite = false;
   }
}

class NotificationSettings {
   constructor() {
      this.from = '';
      this.fromName = '';
      this.subject = '';
      this.bodyHtml = 'Your scheduled report is ready to download: <a href="https://client.mdl.io?route=downloads&search=##reportName##">here</a>';
      this.bodyText = 'Your scheduled report is ready to download: https://client.mdl.io?route=downloads&search=##reportName##';
   }
}

class Cron {
   constructor(initVal, isDebug) {
      // alert('initVal=' + JSON.stringify(initVal));
      let schedule = '* * * * *';
      let startDate = '';
      let endDate = ''
      let repeat = 0;

      if (initVal.cronStart) {
         const dateParts = format(parseISO(initVal.cronStart), DATETIME_FORMAT).split(' ');
         startDate = dateParts[0];
         const timeParts = dateParts[1].split(':');

         if (initVal.cron) {
            schedule = initVal.cron;
            if (initVal.cronRepeat)
               repeat = initVal.cronRepeat;
            else if (initVal.cronEnd)
               endDate = format(parseISO(initVal.cronEnd), DATETIME_FORMAT).split(' ')[0];
         } else {
            repeat = 1;
            schedule = parseInt(timeParts[1]) + ' ' + parseInt(timeParts[0]) + ' * * *';
         }
      }

      this._id = initVal._id || '';
      this.terminated = initVal.terminated || false;
      this.name = initVal.name || '';
      this.emails = initVal.notificationEmails || '';
      this.timezone = initVal.cronTZ || '';
      this.schedule = schedule;
      this.startDate = startDate;
      this.endDate = endDate;
      this.repeat = repeat;
      // this.offset = (initVal.hasOwnProperty('instruction') && initVal.instruction.offset) || '';
      this.offset = (hasOwn(initVal, 'instruction') && initVal.instruction.offset) || '';

      this.ftp = new Ftp(initVal);

      this.debug = initVal.debug ? true : (isDebug ? true : false);
   }
}

let DEBUG;
const DATE_FORMAT = 'yyyy-MM-dd';
const DATETIME_FORMAT = DATE_FORMAT + ' H:mm:ss';
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const HOST_PATTERN = /^(sftp|https):\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
const HOST_PATTERN = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
// const FOLDER_PATTERN = /^[^\?\*\"\>\<\:\|\\\/]+[^\?\*\"\>\<\:\|]*[^\?\*\"\>\<\:\|\\\/]+$/;
const FOLDER_PATTERN = /^[^\?\*\"\>\<\:\|]*$/;
const FORM_VALUES = {
   period: '',
   week: [],
   day: [],
   hour: '',
   minute: '',
   offset: '',
   customOffset: 4,
   endType: '',
   rdl: ''
};
const FORM_RULES = {
   name: [],
   period: [],
   week: [],
   day: [],
   hour: [],
   timezone: [],
   offset: [],
   customOffset: [],
   startDate: [],
   endDate: [],
   repeat: [],
   protocol: [],
   host: [],
   folder: [],
   username: [],
   password: [],
   emails: [],
   rdl: [],
   from: []
}

function _log(msg) {
  if (DEBUG) console.log(`BtCronSchedulerChild V250131.1 says => ${msg}`);
}

export default {
   name: "BtCronSchedulerChild",

   props: {
      value: {
         type: Object,
         default: () => {}
      },
      rdls: {
         type: Array,
         default: () => []
      },
      btnAlign: {
         type: String,
         default: "left",
         validator: value => ['left', 'right'].indexOf(value) > -1
      },
      btnColor: {
         type: String,
         default: "gray darken-1"
      },
      btnIcon: {
         type: String,
         default: "add"
      },
      btnLabel: {
         type: String,
         default: "Schedule"
      },
      btnText: {
         type: Boolean,
         default: false
      },
      btnDisabled: {
         type: Boolean,
         default: false
      },
      btnRole: {
         type: String,
         default: "edit",
         validator: value => { return ['edit', 'delete', 'view'].indexOf(value.toLowerCase()) !== -1 }
      },
      consumerType: {
         type: String,
         default: "bi",
         validator: value => ['bi', 'export'].indexOf(value) > -1
      },
      debug: {
         type: Boolean,
         default: false
      },
      debugSwitch: {
         type: Boolean,
         default: false
      },
      dialogTitle: {
         type: String,
         default: "Setup Your Schedules:"
      },
      nameLabel: {
         type: String,
         default: "Name"
      },
      namePlaceholder: {
         type: String,
         default: ""
      },
      nameOutlined: {
         type: Boolean,
         default: false
      },
      nameValidator: {
         type: Function
      },
      emailLabel: {
         type: String,
         default: "Notification Emails"
      },
      emailPlaceholder: {
         type: String,
         default: ""
      },
      emailOutlined: {
         type: Boolean,
         default: false
      },
      periodLabel: {
         type: String,
         default: ""
      },
      periodPlaceholder: {
         type: String,
         default: "how often?"
      },
      weekLabel: {
         type: String,
         default: ""
      },
      weekPlaceholder: {
         type: String,
         default: "what day(s) of the week?"
      },
      dayLabel: {
         type: String,
         default: ""
      },
      dayPlaceholder: {
         type: String,
         default: "what day(s) of the month?"
      },
      hourLabel: {
         type: String,
         default: ""
      },
      hourPlaceholder: {
         type: String,
         default: "what time?"
      },
      hourOptions: {
         type: Array,
         default: () => [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
         validator: value => { 
            value.forEach(o => { if (o < 0 || o > 23) return false; });
            return true;
         }
      },
      minuteLabel: {
         type: String,
         default: ""
      },
      minutePlaceholder: {
         type: String,
         default: ""
      },
      offsetLabel: {
         type: String,
         default: ""
      },
      offsetPlaceholder: {
         type: String,
         default: "Day Offset"
      },
      offsetMaxDays: {
         type: Number,
         default: 93
      },
      protocolLabel: {
         type: String,
         default: ""
      },
      protocolPlaceholder: {
         type: String,
         default: "FTP Protocol?"
      },
      hostLabel: {
         type: String,
         default: ""
      },
      hostPlaceholder: {
         type: String,
         default: "Host"
      },
      folderLabel: {
         type: String,
         default: ""
      },
      folderPlaceholder: {
         type: String,
         default: "Folder"
      },
      portLabel: {
         type: String,
         default: ""
      },
      portPlaceholder: {
         type: String,
         default: "Port"
      },
      usernameLabel: {
         type: String,
         default: ""
      },
      usernamePlaceholder: {
         type: String,
         default: "User Name"
      },
      passwordLabel: {
         type: String,
         default: ""
      },
      passwordPlaceholder: {
         type: String,
         default: "Password"
      },
      rdlLabel: {
         type: String,
         default: "Override RDL"
      },
      rdlPlaceholder: {
         type: String,
         default: ""
      },
      showOffset: {
         type: Boolean,
         default: true
      },

      //from Download
      fileNameLabel: {
         type: String,
         default: "File Name"
      },
      fileNamePlaceholder: {
         type: String,
         default: ""
      },
      fileNameOutlined: {
         type: Boolean,
         default: false
      },
      fileNamePattern: {
         type: RegExp,
         default: () => { return /^([A-Za-z0-9'\!\-\_\.\*\(\))]){5,100}$/ }
      },
      fileNameHint: {
         type: String,
         default: "valid characters: A-Z a-z 0-9 ( ' ! - _ . * )"
      },
      nextScheduleData: {
         type: Object,
         default: () => {}
      },
      prevScheduleData: {
         type: Object,
         default: () => {}
      },
      timezones: {
         type: Array,
         // default: () => []
      },
      isAuthorized: {
         type: Boolean,
         default: true
      },
      isJsonServer: {
         type: Boolean,
         default: false
      },
      noPermissionMessage: {
         type: String,
         default: "Contact your admin to see how you can get this feature."
      },
      small: {
         type: Boolean,
         default: false
      },
      xSmall: {
         type: Boolean,
         default: true
      },

      //notification settings
      fromLabel: {
         type: String,
         default: 'From Email'
      },
      fromPlaceholder: {
         type: String,
         default: ''
      },
      fromNameLabel: {
         type: String,
         default: 'From Name'
      },
      fromNamePlaceholder: {
         type: String,
         default: ''
      },
      subjectLabel: {
         type: String,
         default: 'Subject'
      },
      subjectPlaceholder: {
         type: String,
         default: ''
      },
      bodyHtmlLabel: {
         type: String,
         default: 'Body HTML'
      },
      bodyHtmlPlaceholder: {
         type: String,
         default: ''
      },
      bodyTextLabel: {
         type: String,
         default: 'Body Text'
      },
      bodyTextPlaceholder: {
         type: String,
         default: ''
      }
   },

   data() {
      return {
         dialog: false,
         cronPanels: [0,1,2,3],
         // downloadPanels: [0],
         // ftpPanels: [0],
         // notificationPanels: [0],
         viewPanels: [0,1],

         cron: new Cron({}, this.debugSwitch),
         formValues: JSON.parse(JSON.stringify(FORM_VALUES)),
         formRules: JSON.parse(JSON.stringify(FORM_RULES)),

         periodItems: [
            { text: "Once", value: "O" },
            { text: "Daily", value: "D" },
            { text: "Weekly", value: "W" },
            { text: "Monthly", value: "M" }
         ],
         hourItems: [],
         minuteItems: [
            { text: "00", value: "0" },
            { text: "15", value: "15" },
            { text: "30", value: "30" },
            { text: "45", value: "45" }
         ],
         weekItems: [
            { text: "Sun", value: "0" },
            { text: "Mon", value: "1" },
            { text: "Tue", value: "2" },
            { text: "Wed", value: "3" },
            { text: "Thu", value: "4" },
            { text: "Fri", value: "5" },
            { text: "Sat", value: "6" }
            //V230227.1 { text: "Sun", value: "7" }
         ],
         dayItems: [
            { text: "1st", value: "1" },
            { text: "2nd", value: "2" },
            { text: "3rd", value: "3" },
            { text: "4th", value: "4" },
            { text: "5th", value: "5" },
            { text: "6th", value: "6" },
            { text: "7th", value: "7" },
            { text: "8th", value: "8" },
            { text: "9th", value: "9" },
            { text: "10th", value: "10" },
            { text: "11th", value: "11" },
            { text: "12th", value: "12" },
            { text: "13th", value: "13" },
            { text: "14th", value: "14" },
            { text: "15th", value: "15" },
            { text: "16th", value: "16" },
            { text: "17th", value: "17" },
            { text: "18th", value: "18" },
            { text: "19th", value: "19" },
            { text: "20th", value: "20" },
            { text: "21st", value: "21" },
            { text: "22nd", value: "22" },
            { text: "23rd", value: "23" },
            { text: "24th", value: "24" },
            { text: "25th", value: "25" },
            { text: "26th", value: "26" },
            { text: "27th", value: "27" },
            { text: "28th", value: "28" },
            { text: "29th", value: "29" },
            { text: "30th", value: "30" },
            { text: "31st", value: "31" },
         ],
         offsetItems: [
            { text: "Schedule Day", value: "current-day" },
            { text: "1 Day Before", value: "day-before-1" },
            { text: "2 Days Before", value: "day-before-2" },
            { text: "3 Days Before", value: "day-before-3" },
            { text: "7 Days Before", value: "day-before-7" },
            { text: "15 Days Before", value: "day-before-15" },
            { text: "30 Days Before", value: "day-before-30" },
            { text: "Last Week", value: "last-week" },
            { text: "Last Month", value: "last-month" },
            { text: "Last Quarter", value: "last-quarter" },
            { text: "Current Week", value: "current-week" },
            { text: "Current Month", value: "current-month" },
            { text: "Current Quarter", value: "current-quarter" },
            { text: "Custom Offset...", value: "C" }
         ],
         endTypeItems: [
            { text: "End Date", value: "D" },
            { text: "Numbers", value: "N" }
         ],
         protocolItems: [
            { text: "Download", value: "" },
            { text: "SFTP (SSH)", value: "sftp" }
            // { text: "FTPS", value: "ftps" }
         ],
         showPassword: false,
         rules: {
            required: value => !!value || "Value is required!",
            cronName: value => !this.myNameValidator || this.myNameValidator(value) || "Name already exists!",
            emails: value => { 
               let result = [];
               value.split(',').forEach(e => { if (!EMAIL_PATTERN.test(e)) result.push(`'${e}' is invalid!`); });   //false?
               return Boolean(result.length === 0) || result.join(', ');
            },
            fileName: value => this.fileNamePattern.test(value) || "Value is invalid!",
            // offsetRange: value => value <= this.offsetMaxDays || `Value cannot be greater than ${this.offsetMaxDays}`,
            offsetRange: value => {
               if (value < 4)
                  return `Value cannot be less than 4!`;
               else if (value > this.offsetMaxDays)
                  return `Value cannot be greater than ${this.offsetMaxDays}!`;
               else if (['7', '15', '30'].includes(value))
                  return 'Please select the value from the dropdown!';
               else
                  return true;
            },
            startDate: value => {
               if (this.value._id || this.formValues.period != 'D')
                  return true;
               else {
                  const sd = setMinutes(setHours(parse(value, 'MM/dd/yyyy', new Date(), {additionalDigits:0}), this.formValues.hour), this.formValues.minute);
                  return isAfter(sd, new Date()) || "Value is past!";
               }
            },
            endDate: value => {
               const actual = parse(value, 'MM/dd/yyyy', new Date(), {additionalDigits:0});
               const min = addDays(parse(this.cron.startDate, DATE_FORMAT, new Date(), {additionalDigits:0}), 2);
               return isAfter(actual, min) || isEqual(actual, min) || "Value is invalid!";
            },
            arrayRequired: value => value.length > 0 || "Value is required!",
            host: value => HOST_PATTERN.test(value) || "Value is invalid!",
            folder: value => FOLDER_PATTERN.test(value) || "Value is invalid!",
            email: value => EMAIL_PATTERN.test(value) || "Value is invalid!",
         },
         isCronFormValid: true,
         isFirstSubmit: true,

         //from Download
         downloadSettings: new DownloadSettings(),
         fileNameRules: [],
         delimiterItems: [
            { text: "Comma (,)", value: "," },
            { text: "Pipe (|)", value: "|" },
            { text: "Tab (\\t)", value: "\\t" }
         ],

         dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
         menuStart: false,
         menuEnd: false,
         testOption: '',
         testDataWithRepeat: false,
         testDataWithFtp: false,
         colTitleClass: 'font-weight-bold',
         colContentClass: '',
         minStartDate: format(new Date(), DATE_FORMAT),
         showTooltip: false,
         folderHint: 'No ?*"<>:|\ and no / to begin or end with',
         currPassword: '',
         passwordHint: '',
         notificationSettings: new NotificationSettings(),
         overrideRdl: '',
         exportInstruction: {},
         apiService: new APIService(this.consumerType, this.debug, this.isJsonServer),
         cronsData: [],
         myNameValidator: null,
         myTimezones: [],
         dialogStyle: this.consumerType === 'export' ? '' : 'height: 95vh'
      }
   },

   computed: {
      user() {
         return this.$store.getters.user;
      },
      loading() {
         return this.btnRole === 'view' && Object.keys(this.value).length === 0
            ? true : false;
      },
      weekdaysNeeded() {
         if (this.formValues.week.length === 0)
            return true;

         for (let i=1; i<=5; i++) {
            if (this.formValues.week.indexOf(i.toString()) === -1) return true;
         }
         
         return false;
      },
      cronSchedule() {
         return (this.formValues.minute || '*') + ' ' +
            (this.formValues.hour || '*') + ' ' +
            (this.formValues.day.join(',') || '*') + ' ' +
            '* ' +
            (this.formValues.week.join(',') || '*');
      },
      prettyCronSchedule() {
         // return (this.cron.oneTimeOnly ? 'Once' : this.periodItems.find(i => i.value === this.formValues.period).text) + 
         const period = this.periodItems.find(i => i.value === this.formValues.period);
         return (period ? period.text + ' ' : '') +
            cronstrue.toString(this.cronSchedule).replace('At', 'at');
      },
      offsetHint() {
         // alert('in offsetHint(): this.formValues.offset='+this.formValues.offset+'\nthis.formValues.customOffset='+this.formValues.customOffset)
         let hint = '';
         let offsetParts;
         switch (this.formValues.offset) {
            case '':
               return 'Will select records without considering their events';
            case 'current-day':
               hint = 'on the scheduled day only';
               break;
            case 'current-week':
               hint = 'from Monday to the scheduled day';
               break;
            case 'current-month':
               hint = 'from the start of the current month';
               break;
            case 'current-quarter':
               hint = 'from the start of the current quarter';
               break;
            case 'last-week':
               hint = 'from last Monday to Sunday';
               break;
            case 'last-month':
               hint = 'from the start of the previous month';
               break;
            case 'last-quarter':
               hint = 'from the start of the previous quarter';
               break;
            case 'C':
               hint = `in ${this.formValues.customOffset} days before the scheduled day`;
               break;
            default:
               offsetParts = this.formValues.offset.split('-');
               hint = `in ${offsetParts[2]} ${offsetParts[0]}${offsetParts[2]>1?'s':''} ${offsetParts[1]} the scheduled day`;
               break;
         }

         return 'Will select records that have one or more events ' + hint;
      },
      minEndDate() {
         let endDate = parse(this.cron.startDate, DATE_FORMAT, new Date(), {additionalDigits:0});
         return format(addDays(endDate,2), DATE_FORMAT);
      },
      formattedStartDate () {
         return this.formatDate(this.cron.startDate);
      },
      formattedEndDate () {
         if (this.cron.endDate)
            return this.formatDate(this.cron.endDate);
         else return this.cron.endDate;
      },
      prevScheduleProps() {
         return this.getScheduleProps(this.prevScheduleData);
      },
      nextScheduleProps() {
         return this.getScheduleProps(this.nextScheduleData);
      }
   },

   watch: {
      dialog(val) {
         if (val) {
            this.$emit('click');
            setTimeout(() => {
               this.initDialog();
            }, 10);
         } else {
            this.closeDialog();
         }
      },
      value() {
         if (this.dialog)
            this.initDialog();
      },
      async debugSwitch(val) {
         await this.setNameValidator(val);
      }
   },

   methods: {
      logout() {
         this.$router.push('/');
      },
      btnClicked() {
         // alert('isAuthorized=' + this.isAuthorized + ', this.btnRole=' + this.btnRole);
         if (this.isAuthorized) {
            if (this.btnRole === 'delete') this.$emit('submit');
            else this.dialog = true;
         } else {
            this.showTooltip = true;
            setTimeout(() => {
               this.showTooltip = false;
            }, 5000);
         }
      },
      startDateChanged() {
      },
      initDialog() {
         // alert('in initDialog(): value=' + JSON.stringify(this.value));
         this.cron = new Cron(this.value, this.debugSwitch);
         this.formValues = JSON.parse(JSON.stringify(FORM_VALUES));
         this.formRules = JSON.parse(JSON.stringify(FORM_RULES));

         _log('in initDialog() start: this.value=' + JSON.stringify(this.value) +
            '\ncron=' + JSON.stringify(this.cron)
         );

         const cronParts = this.cron.schedule.split(' ');
         if (cronParts[2] != '*') {
            this.formValues.period = 'M';
            this.periodChanged();
            this.formValues.day = cronParts[2].split(',');
            this.dayChanged();
         } else if (cronParts[4] != '*') {
            this.formValues.period = 'W';
            this.periodChanged();
            this.formValues.week = cronParts[4].split(',');
            this.weekChanged();
         } else if (cronParts[0] != '*' && cronParts[1] != '*') {
            this.formValues.period = 'D';
            this.periodChanged();
         } else {
            // this.formValues.period = '';
            this.periodChanged();
         }

         if (cronParts[1] != '*')
            this.formValues.minute = cronParts[0];
         // else
         //    this.formValues.minute = '';

         // if (cronParts[1] != '*') {
         //    this.formValues.hour = cronParts[1];
         //    this.hourChanged();
         // }
         if (cronParts[1] != '*')
            this.formValues.hour = cronParts[1];
         // else
         //    this.formValues.hour = '';
         this.hourChanged();

         if (this.cron.repeat === 1) {
            this.formValues.period = 'O';
            this.periodChanged();
         } 

         if (this.offsetItems.find(o => o.value === this.cron.offset))
            this.formValues.offset = this.cron.offset;
         else if (this.cron.offset) {
            this.formValues.offset = 'C';
            this.formValues.customOffset = this.cron.offset.split('-')[2];
         } 
         //TODO:? else this.formValues.offset = '';

         if (this.cron.endDate)
            this.formValues.endType = 'D';
         else if (this.cron.repeat && this.cron.repeat > 1)
            this.formValues.endType = 'N';

         this.offsetChanged();
         this.protocolChanged();

         // if (this.value.hasOwnProperty('instruction') && this.value.instruction.hasOwnProperty('download'))
         if (hasOwn(this.value, 'instruction') && hasOwn(this.value.instruction, 'download'))
            this.downloadSettings = this.value.instruction.download;
         else
            this.downloadSettings = new DownloadSettings();

         // if (this.value.hasOwnProperty('instruction') && this.value.instruction.hasOwnProperty('notification'))
         if (hasOwn(this.value, 'instruction') && hasOwn(this.value.instruction, 'notification'))
            this.notificationSettings = this.value.instruction.notification;
         else
            this.notificationSettings = new NotificationSettings();

         if (this.consumerType === 'export')
            this.exportInstruction = this.value.instruction;
         else
            this.exportInstruction = {};

         if (this.rdls.length > 0) {
            this.overrideRdl = this.getOverrideRdl();
            if (this.overrideRdl && this.overrideRdl != 'account-download-contacts' && this.overrideRdl != 'account-download-events') {
               if (this.rdls.indexOf(this.overrideRdl) > -1) {
                  this.formValues.rdl = this.overrideRdl;
                  this.formRules.rdl.push(this.rules.required);
               } else
                  alert(`'${this.overrideRdl}' could not be found!`);
            }
         } else
            this.overrideRdl = '';

         // if (this.$refs.name)
         //    this.$refs.name.focus();

         setTimeout(() => {
            if (this.value._id) {
               this.currPassword = this.cron.ftp.password;
               this.cron.ftp.password = '';
               // this.passwordHint = "Your password is hidden. Don't touch it if you don't want to change it."
               this.passwordHint = "Your password is hidden. Enter new value only if you want to change it."
               if (this.$refs.name)
                  this.$refs.name.focus();
            }
         }, 10);

         _log('in initDialog() end: formValues=' + JSON.stringify(this.formValues) +
            '\ncron=' + JSON.stringify(this.cron)
         );
      },
      nameChanged () {
         if (this.formRules.name.length === 0) {
            this.formRules.name.push(this.rules.required);
            this.formRules.name.push(this.rules.cronName);
         }
      },
      periodChanged() {
         this.formValues.week = [];
         this.formRules.week = [];
         this.formValues.day = [];
         this.formRules.day = [];
         if (this.formValues.period === 'O')
            this.formValues.endType = '';
      },
      weekChanged() {
         this.formValues.week.sort();
      },
      dayChanged() {
      },
      hourChanged() {
         if (!this.formValues.minute)
            this.formValues.minute = "0";
      },
      offsetChanged(val) {
         if (val === 'C')
            this.formRules.customOffset.push(this.rules.offsetRange);
         else
            this.formRules.customOffset = [];
      },
      // customOffsetChanged() {
      //    if (this.formRules.customOffset.length === 0)
      //       this.formRules.customOffset.push(this.rules.offsetRange);
      // },
      protocolChanged() {
         if (!this.cron.ftp.protocol)
            this.cron.ftp = new Ftp({});
      },
      hostChanged() {
         if (this.formRules.host.length === 0) {
            this.addRequired(this.formRules.host);
            this.formRules.host.push(this.rules.host);
         }
      },
      folderChanged() {
         if (this.formRules.folder.length === 0)
            this.formRules.folder.push(this.rules.folder);
      },
      usernameChanged() {
         if (this.formRules.username.length === 0)
            this.addRequired(this.formRules.username);
      },
      passwordChanged() {
         if (!this.value._id && this.formRules.password.length === 0)
            this.addRequired(this.formRules.password);
      },
      emailChanged() {
         if (this.formRules.emails.length === 0) {
            this.formRules.emails.push(this.rules.required);
            this.formRules.emails.push(this.rules.emails);
         }
      },

      weekdaysClicked() {
         for (var i=1; i<=5; i++)
            if (this.formValues.week.indexOf(i.toString()) === -1)
               this.formValues.week.push(i.toString());
      },

      addRequired(fld) {
         if (fld.length === 0)
            fld.push(this.rules.required);
      },

      //from Download
      fileNameChanged () {
         if (this.fileNameRules.length === 0) {
            this.fileNameRules.push(this.rules.required);
            this.fileNameRules.push(this.rules.fileName);
         }
      },

      formatDate (date) {
         if (!date) return null

         const [year, month, day] = date.split('-')
         return `${month}/${day}/${year}`
      },
      parseDate (date) {
         if (!date) return null

         const [month, day, year] = date.split('/')
         return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      fromChanged () {
         if (this.notificationSettings.from)
            this.formRules.from = [this.rules.email];
         else
            this.formRules.from = [];
      },

      getInstructionValue(prop) {
         if (this.value && this.value.instruction)
            return this.value.instruction[prop];
         else
            return '';
      },

      getInstructionNotifications() {
         const result = [];
         const notifications = this.getInstructionValue('notifications');
         if (notifications && Array.isArray(notifications)) {
            notifications.forEach(element => {
               if (element.type === 'sftp')
                  element.password = '***';
               if (element.pgpPublicKey)
                  element.pgpPublicKey = '***';
               result.push(element);
            });
         }
         return result;
      },

      cancelClicked() {
         this.$emit('cancel');
         this.dialog = false;
      },

      async submitClicked() {
         this.isCronFormValid = true;
         this.formRules.week = [];
         this.formRules.day = [];
         switch (this.formValues.period) {
            case '':
               this.addRequired(this.formRules.period);
               //alert('this.formRules.period='+this.formRules.period.length)
               break;
            case 'W':
               // this.addRequired(this.formRules.week);
                  this.formRules.week.push(this.rules.arrayRequired);
               //alert('this.formRules.week='+this.formRules.week.length)
               break;
            case 'M':
               // this.addRequired(this.formRules.day);
                  this.formRules.day.push(this.rules.arrayRequired);
               //alert('this.formRules.day='+this.formRules.day.length)
               break;
         }

         if (this.cron.ftp.protocol) {
            this.hostChanged();
            this.folderChanged();
            this.usernameChanged();
            this.passwordChanged();
         }

         // if (this.notificationSettings.fromName) {
         //    this.addRequired(this.formRules.subject);
         //    this.addRequired(this.formRules.body);
         // }

         if (this.isFirstSubmit) {
            this.nameChanged();
            this.emailChanged();
            this.addRequired(this.formRules.hour);
            this.addRequired(this.formRules.timezone);
            if (this.consumerType != 'export') {
               this.addRequired(this.formRules.offset);
            }
            // this.customOffsetChanged();
            this.addRequired(this.formRules.startDate);
            this.formRules.startDate.push(this.rules.startDate);
            this.addRequired(this.formRules.endDate);
            this.formRules.endDate.push(this.rules.endDate);
            this.addRequired(this.formRules.repeat);
            this.fileNameChanged();
            this.isFirstSubmit = false;
         }

         if (this.$refs.cronForm.validate()) {
            // this.cron.schedule = this.cronSchedule;
            // this.cron.prettySchedule = this.prettyCronSchedule;
            // this.cron.offset = this.formValues.offset === 'C' ? this.formValues.customOffset : this.formValues.offset;
            // this.cron.downloadSettings = this.downloadSettings;
            const cronData = this.prepareCronData();
            if (cronData._id) {
               this.$emit("input", cronData);
               this.$emit("submit", cronData);
               this.dialog = false;
            } else {
               this.apiResult = await this.apiService.createCron(cronData, this.cron.debug);
               if (this.apiResult.logout)
                  this.logout();
               else if (!this.apiResult.message) {
                  this.$emit("input", cronData);
                  this.$emit("submit", cronData);
                  this.dialog = false;
               }
            }
         }
      },

      prepareCronData() {
         let cronData = {
            terminated: this.cron.terminated,
            name: this.cron.name,
            notificationEmails: this.cron.emails,
            cronTZ: this.cron.timezone,
            instruction: JSON.parse(JSON.stringify(this.value.instruction)),
            cronDesc: this.prettyCronSchedule
         };
         cronData.instruction.offset = this.formValues.offset === 'C' ? 'day-before-' + this.formValues.customOffset : this.formValues.offset;

         if (!this.value._id && this.formValues.rdl) {
            const urlParts = cronData.instruction.url.split('?');
            const hostParts = urlParts[0].split('/');
            hostParts[hostParts.length - 1] = this.formValues.rdl;
            cronData.instruction.url = hostParts.join('/') + (urlParts.length === 2 ? '?' + urlParts[1] : '');
         }

         if (this.consumerType != 'export') {
            // result.instruction.offset = this.formValues.offset === 'C' ? 'day-before-' + this.formValues.customOffset : this.formValues.offset;
            cronData.instruction.download = this.downloadSettings;
         }

         let startDate = parse(this.cron.startDate, DATE_FORMAT, new Date(), {additionalDigits:0});

         if (this.formValues.period === 'O') {
            startDate = addHours(addMinutes(startDate, this.formValues.minute), this.formValues.hour);
         } else {
            cronData.cron = this.cronSchedule;
            if (this.formValues.endType === 'D')
               cronData.cronEnd = parse(this.cron.endDate, DATE_FORMAT, new Date(), {additionalDigits:0});
            else if (this.formValues.endType === 'N')
               cronData.cronRepeat = this.cron.repeat;
         }

         cronData.cronStart = startDate.toISOString();

         if (this.cron.ftp.protocol) {
            cronData.transferProtocol = this.cron.ftp.protocol;
            cronData.ftpHost = this.cron.ftp.host;
            cronData.ftpFolder = this.cron.ftp.folder;
            cronData.ftpPort = this.cron.ftp.port;
            cronData.ftpUser = this.cron.ftp.username;
            if (this.value._id) {
               if (this.cron.ftp.password)
                  cronData.ftpPass = this.cron.ftp.password;
               else
                  cronData.ftpPass = this.currPassword;
            } else
               cronData.ftpPass = this.cron.ftp.password;

            // Object.keys(result.instruction.download).forEach(key => {
            //    result[key] = result.instruction.download[key];
            // });
         }

         if (this.notificationSettings.from)
            cronData.instruction.notification = this.notificationSettings;

         if (this.cron._id)
            cronData._id = this.cron._id;

         return cronData;
      },

      closeDialog() {
         setTimeout(() => {
            this.isFirstSubmit = true;
            this.formRules = JSON.parse(JSON.stringify(FORM_RULES));
            this.fileNameRules = [];
            this.$emit('input', {});
         }, 10);
      },

      //V221027.1: Not in use
      // isObjectEmpty(obj) {
      //    for(var key in obj) {
      //       if (obj.hasOwnProperty(key))
      //          return false;
      //    }
      //    return true;
      // },

      getTimezoneName(timezoneAlias) {
         const timezone = this.myTimezones.find(tz => tz.aliasOf === timezoneAlias);
         return timezone ? timezone.name : 'no timezone';
      },
      getScheduleProps(schedule) {
         var arrProps = [];

         for (var prop in schedule) {
            // if (schedule.hasOwnProperty(prop) && schedule[prop]) {
            if (hasOwn(schedule, prop) && schedule[prop]) {
               switch (prop) {
                  case 'cron':
                  case 'consumerCode':
                  case 'accountId':
                  case '__v':
                     break;
                  default:
                     arrProps.push(prop);
                     break;
               }
            }
         }
      
         _log('in getProps(): arrProps=' + JSON.stringify(arrProps));
         return arrProps;
      },
      
      getOverrideRdl() {
         // if (this.value.hasOwnProperty('instruction')) {
         if (hasOwn(this.value, 'instruction')) {
            const urlParts = this.value.instruction.url.split('?')[0].split('/');
            const rdl = urlParts[urlParts.length - 1];
            // if (rdl != 'account-download-contacts' && rdl != 'account-download-events')
               return rdl;
         }
         return '';
      },

      async debugChanged(val) {
         await this.setNameValidator(val);
      },

      async setNameValidator(isDebug) {
         if (this.nameValidator)
            this.myNameValidator = this.nameValidator;
         else {
            this.apiResult = await this.apiService.getCrons('terminated=false', isDebug);
            if (this.apiResult.logout)
               this.logout();
            else if (!this.apiResult.message) {
               this.cronsData = this.apiResult.data;
               this.myNameValidator = function(name) {
                  return this.cronsData.findIndex(c => c.name.toLowerCase() === name.toLowerCase()) === -1;
               };
            }
         }
      }
   },

   beforeCreate() {
   },

   async created() {
      // alert('in created(): value=' + JSON.stringify(this.value));
      // alert(Boolean(this.value._id));
      DEBUG = this.debug;

      await this.setNameValidator(this.debugSwitch);

      if (this.timezones)
         this.myTimezones = this.timezones;
      else {
         const allTimezones = countriesAndTimezones.getAllTimezones();
         const stdOffset = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset() * -1;
         let currTz;
         Object.keys(allTimezones).forEach(key => {
            currTz = allTimezones[key];
            if (currTz.utcOffset === stdOffset && currTz.country && currTz.aliasOf) {
               if (this.myTimezones.findIndex(tz => tz.aliasOf === currTz.aliasOf) === -1)
                  this.myTimezones.push(currTz);
            }
         });
      }

      this.hourOptions.forEach(h => {
         if (h === 0) this.hourItems.push({ text: "12 AM", value: "0" });
         else if (h > 0 && h < 12) this.hourItems.push({ text: h.toString() + " AM", value: h.toString() });
         else if (h === 12) this.hourItems.push({ text: "12 PM", value: "12" });
         else if (h > 12 && h < 24) this.hourItems.push({ text: (h - 12).toString() + " PM", value: h.toString() });
      });

      if (this.consumerType === 'export')
         this.offsetItems.unshift({ text: "No Offset", value: "" });
   }
};
</script>

<style scoped>
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
.v-expansion-panel-content__wrap {
   padding: 24px 16px !important;
}
</style>