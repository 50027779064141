<!-- HISTORY:
   V250225.1: Added 'paused' to statusItems.
   V250214.1: 1st release.
-->
<template>
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title>
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <v-icon class="pr-1">connect_without_contact</v-icon>
            <span>Export Actions</span>
         </h1>
      </v-card-title>

      <v-card-text class="ml-2 px-5 pb-0">
         <v-form lazy-validation
            ref="mainForm"
            v-model="isMainFormValid"
         >
            <v-row>
               <v-col xs="12" sm="12" md="3" class="py-2">
                  <v-autocomplete dense persistent-hint required
                     ref="app"
                     hint="* App"
                     placeholder="select an app..."
                     :items="appItems"
                     :rules="[rules.required]"
                     v-model="formData.appCode"
                     @change="appChanged"
                  ></v-autocomplete>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-2">
                  <v-select dense persistent-hint hide-selected required
                     ref="status"
                     placeholder="select a status..."
                     hint="* Status"
                     :items="statusItems"
                     :prepend-inner-icon="statusIcon"
                     :rules="[rules.required]"
                     v-model="formData.status"
                     @change="statusChanged"
                  ></v-select>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="py-2">
                  <bt-date-picker
                     :debug="debug"
                     :dd-custom-label="true"
                     dd-label="select a date range..."
                     :dd-monthly-items="datePickerMonthlyItems"
                     dd-value="TM"
                     :dp-no-title="false"
                     :dp-max-range="365"
                     :rules="[rules.arrayRequired]"
                     v-model="formData.dateRange"
                     @date-range-change="dateRangeChanged"
                  ></bt-date-picker>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="pt-1 mt-0">
                  <v-text-field clearable persistent-hint
                     ref="account"
                     autocomplete="off"
                     class="pt-1 mt-0"
                     hint="Account Number"
                     v-model="formData.account"
                     @keyup="accountChanged"
                     @click:clear="accountChanged"
                  ></v-text-field>
               </v-col>
               <v-col xs="12" sm="12" md="1" class="py-2">
                  <v-switch hide-details
                     label="Debug"
                     v-model="formData.debug"
                     @change="debugChanged"
                  ></v-switch>
               </v-col>
               <v-col xs="12" sm="12" md="2" class="pl-5 py-5 mt-1">
                  <v-btn small
                     color="primary"
                     :disabled="!isCriteriaChanged"
                     @click="getListReport()"
                  >Get Report
                     <v-icon right dark>summarize</v-icon>
                  </v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-card-text>

      <v-card-text>
         <v-tabs
            class="elevation-2"
            background-color="grey lighten-2 accent-4"
            slider-color="black"
            v-model="tab"
         >
            <v-tab>List</v-tab>

            <v-tabs-items v-model="tab">
               <v-tab-item class="pt-5"><!-- List -->
                  <v-data-table dense fixed-header show-expand single-expand
                     class="elevation-1"
                     item-key="_id"
                     :expanded.sync="expandedItems"
                     :footer-props="listFooterProps"
                     :headers="listHeaders"
                     :hide-default-footer="listItemsCount <= 5"
                     :items="listItems"
                     :items-per-page="10"
                     :loading="listLoading"
                     :loading-text="$t('loading-text')"
                     :no-data-text="$t('no-data-text', { value: 'records' })"
                     :no-results-text="$t('no-results-text', { value: 'records' })"
                     :options.sync="listOptions"
                     :server-items-length="listItemsCount"
                     @update:expanded="itemExpanded"
                  >
                     <template v-slot:[`item.processCount`]="{item}">
                        {{ formatNumber(item.processCount) }}
                     </template>
                     <template v-slot:[`item.creationDate`]="{ item }">
                        {{ formatDate(item.creationDate) }}
                     </template>
                     <template v-slot:[`item.processLastActivity`]="{ item }">
                        {{ formatDate(item.processLastActivity) }}
                     </template>
                     <template v-slot:expanded-item="{ item }">
                        <!-- appCode, exportDelimiter, appInstruction -->
                        <td :colspan="listHeaders.length + 1" class="py-2" valign="top" dense>
                           <ul class="pl-3">
                              <li>
                                 <span class="expanded-header">ID: </span>
                                 <span class="expanded-content">{{item._id}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Parent Account ID: </span>
                                 <span class="expanded-content">{{item.parentAccountId}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Debug: </span>
                                 <span class="expanded-content">{{item.debug}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Export ID: </span>
                                 <span class="expanded-content">{{item.exportId}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Export Signed URL: </span>
                                 <span class="expanded-content">{{item.exportSignedUrl}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Action ID: </span>
                                 <span class="expanded-content">{{item.actionId}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Process Status: </span>
                                 <span class="expanded-content"><v-icon small :color="currIcon.color">{{currIcon.icon}}</v-icon> {{item.processStatus}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Process Recovery Count: </span>
                                 <span class="expanded-content">{{item.processRecoveryCount}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Framework State: </span>
                                 <span class="expanded-content">{{JSON.stringify(item.frameworkState)}}</span>
                              </li>
                              <li>
                                 <span class="expanded-header">Process State: </span>
                                 <span class="expanded-content">{{JSON.stringify(item.processState)}}</span>
                              </li>
                           </ul>
                        </td>
                     </template>
                  </v-data-table>
               </v-tab-item>
            </v-tabs-items>
         </v-tabs>
      </v-card-text>
   </v-card>
</v-container>
</template>

<script>
import BtDatePicker from "./BtDatePicker.vue";
import { APIService } from '../services/as-api-service.js';
import { format, parseISO } from "date-fns";
import { developersAccess, appList } from '../mixins/cs-apps.js';
// import { sleep } from '../mixins/bt-mixin.js';

const NAME = "AsExportActions";
const SAVED_APPCODE_ITEM = 'MindFireInc_ExportActions_SavedAppCode';
const SAVED_STATUS_ITEM = 'MindFireInc_ExportActions_SavedStatus';

export default {
   name: NAME,

   components: {
      BtDatePicker
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },

      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         statusItems: [ 'waiting', 'paused', 'processing', 'completed', 'recovery', 'rejected' ],
         datePickerMonthlyItems: [
            { text: "Current Month", value: "TM" },
            { text: "Last Month", value: "LM" }
         ],
         listHeaders: [
            { text: 'Name', value: 'actionName', align: 'left', sortable: false },
            { text: 'Account', value: 'accountId', align: 'right', sortable: false },
            { text: 'Author', value: 'actionAuthor', align: 'left', sortable: false },
            { text: 'Processed Count', value: 'processCount', align: 'right', sortable: false },
            { text: 'Creation Date', value: 'creationDate', align: 'left', sortable: false },
            { text: 'Last Activity', value: 'processLastActivity', align: 'left', sortable: false }
         ],
         rules: {
            required: value => !!value || "Value is required!",
            arrayRequired: value => value.length || "Value is required!",
         },
         jwt: {},
         apiService: null,
         isMainFormValid: false,
         isCriteriaChanged: true,
         formData: {
            appCode: '',
            status: '',
            dateRange: [],
            account: '',
            debug: true
         },
         statusIcon: '',
         tab: null,
         listLoading: false,
         listFooterProps: {
            itemsPerPageOptions: [5, 10, 15, 20],
            showFirstLastPage: true,
            disableItemsPerPage: false
         },
         listOptions: {},
         listItemsCount: 0,
         listItems: [],
         expandedItems: [],
         appItems: [],
         currIcon: {},
         savedAppCode: ''
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      }
   },

   watch: {
      token() {
         this.init();
         // this.nextAction();
      },

      listOptions: {
         handler(val) {
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
            }
            this.getListItems();
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----${NAME} V250225.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      async init() {
         // alert('in init(): token=' + this.token);
         if (this.token) {
            this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
            this.apiService = new APIService(this.token, this.debug, this.isActualEndpoint);
            this.statusChanged(this.formData.status);
            // // await this.getListItemsCount();
            if (developersAccess().includes(this.jwt.email))
               this.appItems = appList();
            else
               this.appItems = appList().filter(app => app.onProduction);

            if (this.appItems.length) {
               const app = this.appItems.find(app => app.value === this.savedAppCode);
               this.formData.appCode = app ? this.savedAppCode : this.appItems[0].value;
            }
         }
      },

      async getListItemsCount() {
         this.listLoading = true;
         const result = await this.apiService.getExportActionsCount(this.formData);
         if (result.logout)
            this.logout();

         this.listItemsCount = result.message ? 0 : result.data;
         this.listOptions.page = 1;
         this.listLoading = false;
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.listOptions);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getListItems();
         else
            this.listOptions = newOptions;
      },

      async getListItems() {
         this.expandedItems = [];
         this.listItems = [];
         if (this.listItemsCount) {
            this.loadingItems = true;
            const result = await this.apiService.getExportActions(this.formData, this.listOptions.page, this.listOptions.itemsPerPage);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.listItems = result.data;
            }
            this.listLoading = false;
         }
      },

      appChanged() {
         this.isCriteriaChanged = true;
      },

      statusChanged(val) {
         this.statusIcon = this.getIcon(val).icon;
         this.isCriteriaChanged = true;
      },

      dateRangeChanged() {
         this.isCriteriaChanged = true;
      },

      accountChanged() {
         this.isCriteriaChanged = true;
      },

      debugChanged() {
         this.isCriteriaChanged = true;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'paused':
               return { color: 'purple', icon: 'pause' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      formatNumber(number, maxFractionDigits) {
         if (number)
            return new Intl.NumberFormat('en-US', 
               { 
                  maximumFractionDigits: maxFractionDigits || 0
               }).format(number);
         else
            return 0;
      },

      async getListReport() {
         if (this.$refs.mainForm.validate()) {
            await this.getListItemsCount();
            await this.nextAction();
            this.isCriteriaChanged = false;
            localStorage.setItem(SAVED_APPCODE_ITEM, this.formData.appCode);
            localStorage.setItem(SAVED_STATUS_ITEM, this.formData.status);
         }
      },

      formatDate(date) {
         if (date) {
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            return formatteddate;
         }
      },

      itemExpanded(items) {
         if (items.length)
            this.currIcon = this.getIcon(items[0].processStatus);
      }
   },

   created() {
      this.savedAppCode = localStorage.getItem(SAVED_APPCODE_ITEM) ? localStorage.getItem(SAVED_APPCODE_ITEM) : '';
      this.formData.status = localStorage.getItem(SAVED_STATUS_ITEM) ? localStorage.getItem(SAVED_STATUS_ITEM) : 'waiting';
      this.init();
   }
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
</style>