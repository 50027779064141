<template>
<!-- HISTORY: no-version
   V250213.1: Added App Service category and Export & Trigger Actions menus.
   V240313.2: Added Identity Service category and Accounts menu.
   V231002.1: Removed category icons + Made Edge Service category available to the power admin only.
   V230929.1: Added Edge Service category and API Calls menu.
   V230922.1: in userPolicies watch, reset items to avoid duplicating menus.
   V230921.1: Added DDLs and RDLs menus under List Service.
   V230920.1: Added Remote Accesses and Settings menus.
   V230915.1: Added Downloads menu + Applied grouping.
   V230914.1: Added Deletes menu.
   V230911.1: Added DDLs, RDLs and Accounts menus.
   V230901.1: Added Schedules menu.
   V230824.2: Added Exports menu.
   V230824.1: 1st release.
-->
   <nav>
      <v-navigation-drawer permanent
         class="lighten-2 full-height px-0"
         style="background-color: #f6f6f6; height: 91vh; position:sticky; top:50px; z-index:2"
         mini-variant-width="60"
         width="220"
         :mini-variant="miniVariant"
      >
         <v-list class="py-0">
            <v-divider color="white"></v-divider>
            <v-list-item :dense="true">
               <v-list-item-icon>
                  <v-icon class="grey--text">engineering</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="body-2 grey--text font-italic">Admin Service</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </v-list>

         <v-list class="py-0" flat>
            <v-list-item-group v-for="item in currItems"
               :key="item.title"
               disabled
            >
               <v-divider color="white"></v-divider>
               <v-list-item disabled dense
                  class="grey lighten-2 accent-4"
               >
                  <!-- <v-list-item-icon>
                     <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon> -->
                  <v-list-item-content>
                     <v-list-item-title class="caption font-weight-bold" >{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
               <v-divider color="white"></v-divider>

               <v-list-item v-for="subItem in item.subItems"
                  link dense
                  :key="subItem.title"
                  :to="getLink(subItem.route)"
               >
                  <v-list-item-icon>
                     <v-icon right small>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                     <v-list-item-title class="caption font-weight-bold" >{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
               </v-list-item>
            </v-list-item-group>

            <v-divider color="white"></v-divider>

            <v-list-item @click="toggleMenu()">
               <v-list-item-icon>
                  <v-icon>more_horiz</v-icon>
               </v-list-item-icon>
            </v-list-item>
         </v-list>
      </v-navigation-drawer>
   </nav>
</template>

<script>
export default {
   props: {
      baseRoute: {
         type: String,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      },
      miniVariant: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         allItems: [
            {
               icon: '',
               title: 'General',
               subItems: [
                  { icon: 'account_tree', title: 'Accounts', route: 'accounts' }
               ]
            },
            {
               paOnly: true,
               icon: '',
               title: 'Identity Service',
               subItems: [
                  { icon: 'account_tree', title: 'Accounts', route: 'iam-accounts' }
               ]
            },
            {
               paOnly: true,
               icon: '',
               title: 'Edge Service',
               subItems: [
                  { icon: 'api', title: 'API Calls', route: 'api-calls' }
               ]
            },
            {
               icon: 'list',
               title: 'List Service',
               subItems: [
                  { icon: 'file_download', title: 'Imports', route: 'imports' },
                  { icon: 'file_upload', title: 'Exports', route: 'exports' },
                  { icon: 'delete', title: 'Deletes', route: 'deletes' },
                  { icon: 'vpn_key', title: 'Remote Accesses', route: 'remote-accesses' },
                  { icon: 'dashboard', title: 'DDLs', route: 'cs-ddls' },
                  { icon: 'summarize', title: 'RDLs', route: 'cs-rdls' },
                  { icon: 'settings', title: 'Settings', route: 'settings' }
               ]
            },
            {
               icon: 'dashboard',
               title: 'BI Service',
               subItems: [
                  { icon: 'dashboard', title: 'DDLs', route: 'ddls' },
                  { icon: 'summarize', title: 'RDLs', route: 'rdls' },
                  { icon: 'cloud_download', title: 'Downloads', route: 'downloads' }
               ]
            },
            {
               icon: 'schedule',
               title: 'Scheduler Service',
               subItems: [
                  { icon: 'schedule', title: 'Schedules', route: 'schedules' }
               ]
            },
            {
               icon: '',
               title: 'App Service',
               subItems: [
                  { icon: 'connect_without_contact', title: 'Export Actions', route: 'export-actions' },
                  { icon: 'flash_on', title: 'Trigger Actions', route: 'trigger-actions' }
               ]
            }
         ],
         currItems: []
      }
   },

   computed: {
      userPolicies() {
         // alert('in computed.userPolicies(): val=' + JSON.stringify(this.$store.getters.user.policies));
         return this.$store.getters.user.policies;
      }
   },

   watch: { 
      userPolicies: {
         immediate: true,
         deep: true,
         handler (val) {
            // alert('in watch.userPolicies(): val=' + JSON.stringify(val));
            // this.items = [];
            // if (this.$store.getters.user.isAdminUser) {
            //    this.items.push(
            //       {
            //          icon: '',
            //          title: 'General',
            //          subItems: [
            //             { icon: 'account_tree', title: 'Accounts', route: 'accounts' }
            //          ]
            //       },
            //       {
            //          icon: '',
            //          title: 'Edge Service',
            //          subItems: [
            //             { icon: 'api', title: 'API Calls', route: 'api-calls' }
            //          ]
            //       },
            //       {
            //          icon: 'list',
            //          title: 'List Service',
            //          subItems: [
            //             { icon: 'file_download', title: 'Imports', route: 'imports' },
            //             { icon: 'file_upload', title: 'Exports', route: 'exports' },
            //             { icon: 'delete', title: 'Deletes', route: 'deletes' },
            //             { icon: 'vpn_key', title: 'Remote Accesses', route: 'remote-accesses' },
            //             { icon: 'dashboard', title: 'DDLs', route: 'cs-ddls' },
            //             { icon: 'summarize', title: 'RDLs', route: 'cs-rdls' },
            //             { icon: 'settings', title: 'Settings', route: 'settings' }
            //          ]
            //       },
            //       {
            //          icon: 'dashboard',
            //          title: 'BI Service',
            //          subItems: [
            //             { icon: 'dashboard', title: 'DDLs', route: 'ddls' },
            //             { icon: 'summarize', title: 'RDLs', route: 'rdls' },
            //             { icon: 'cloud_download', title: 'Downloads', route: 'downloads' }
            //          ]
            //       },
            //       {
            //          icon: 'schedule',
            //          title: 'Scheduler Service',
            //          subItems: [
            //             { icon: 'schedule', title: 'Schedules', route: 'schedules' }
            //          ]
            //       }
            //    );
            // }

            if (this.$store.getters.user.isPowerAdmin)
               this.currItems = [...this.allItems];
            else if (this.$store.getters.user.isAdminUser)
               this.currItems = this.allItems.filter(item => !item.paOnly);
            else
               this.currItems = [];
         }
      }
   },

   methods: {
      getLink(route) {
         return "/" + this.baseRoute + "/" + route;
      },

      toggleMenu() {
         this.$emit('more-click');
      }
   },
   
   created() { }
}
</script>

<style scope>
   /* .v-list .v-list-item--active .v-list-item--dense .v-list-item__subtitle .v-list-item__subtitle--active {
      color:blue;
      background: #f6f6f6;
  }
   .v-list-item-group .v-item--active .v-list-item--active .v-list-item--dense .v-list-item--link  .v-list-item__content .v-list-item__subtitle--active {
      color:green !important;
      background: #f6f6f6;
  }
   .v-list-item--dense {
      color:purple !important;
      background: #f6f6f6;
  } */
</style>